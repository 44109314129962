<template>
  <!-- <v-container> -->
    <div style="background-color: black">
    <!-- <hello-world /> -->
      <div class="devide" :class="$isMobile() ? 'screen-mobile' : 'screen'" style="background-color: black">
        <v-container>
          <!-- <v-row> -->
          <h1 v-if="!$isMobile()" class="pt-10" style="font-size: 100px; color: white">Life is a Game</h1>
          <h1 v-else class="pt-10" style="font-size: 50px; color: white">Life is a Game</h1>
          <h2 style="color: white">Come play.</h2>
            <!-- <div class="pl-5">
              <toggle-bar-time/>
            </div> -->
          <!-- </v-row> -->
          <v-row>
            <v-col cols="12" lg="5" class="mt-lg-15 pt-lg-10 mt-15 pt-0 pb-0 cyan--text text--lighten-4" :style="$isMobile() ? 'font-size: ;' : 'font-size: 20px;'" style="font-family: 'Courier New', monospace;">
              <b>Life is a Game Development</b> creates educational games and healthcare websites. <b>Our vision</b> is to make learning more fun and to make healthcare more transparant.
            </v-col>
            <v-col cols="12" lg="7" class="mt-0" style="float: right;">
              <planet/>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div class="discover" :class="$isMobile() ? 'screen-mobile' : 'screen'" id="discover" style="background-color: black">
          <!-- <div class="pl-5">
            <toggle-bar-time/>
          </div> -->
        <Paralax/>
      </div>

      <div :class="$isMobile() ? 'screen-mobile' : 'screen'" style="background-color: black">
        <v-container>
          <h1 class="pt-10" style="font-size: 80px; color: white">Learn.</h1>
          <memory-game/>
        </v-container>
      </div>
      <div :class="$isMobile() ? 'screen-mobile' : 'screen'">
        <v-container>
          <h1 class="pt-10" style="font-size: 80px; color: white">Analyse.</h1>
          <v-row>
            <v-col cols="12" class="col-sm-12 col-md-4">
              <graph-bars/>
            </v-col>
            <v-col v-if="!$isMobile()" cols="12" class="d-flex justify-center col-sm-12 col-md-4">
              <dna-scroll/>
            </v-col>
            <v-col v-if="!$isMobile()" cols="12" class="d-flex justify-center col-sm-12 col-md-4">
              <face/>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div :class="$isMobile() ? 'screen-mobile' : 'screen'">
        <v-container>
          <h1 v-if="!$isMobile()" class="pt-10" style="font-size: 80px; color: white">Create.</h1>
          <h1 v-if="$isMobile()" class="pt-10" style="font-size: 80px; color: white">Contact.</h1>
            <!-- <p class="white--text">ekjfwnejn</p> -->
            <drag-div v-if="!$isMobile()"/>
            <draggableDiv v-else>
              <!-- <template slot="header">
                [[[ SOME CONTENT HERE]]]
              </template>
              <template slot="main" >
                [[[ SOME CONTENT HERE]]]
              </template>
              <template slot="footer">
                [[[ SOME CONTENT HERE]]]
              </template> -->
            </draggableDiv>
        </v-container>
      </div>
    </div>
  <!-- </v-container> -->
</template>

<script>
// import ToggleBarTime from '../components/ToggleBarTime'
import Paralax from '../components/Paralax'
import DnaScroll from '../components/DnaScroll2'
// import Game from '../../game/docs/index'
import MemoryGame from '../components/MemoryGame'
import Planet from '../components/Planet'
import Face from '../components/Face'
import draggableDiv from '../components/dragableDiv'
import GraphBars from '../components/GraphBars'
import DragDiv from '../components/DragDiv'

export default {
  name: 'Home',

  components: {
    // ToggleBarTime,
    Paralax,
    // Game,
    DnaScroll,
    MemoryGame,
    Planet,
    Face,
    draggableDiv,
    DragDiv,
    GraphBars,
  },
  created: function () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {

      let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      // let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      // window.innerHeight
      let scrolled = 10 - ((winScroll / (height/3.5)) * 10);
      // let scrolled = 50
      // document.getElementById("discover").style.filter = "blur(5px)";
      document.getElementById("discover").style.filter = "blur(" + scrolled + "px)";

      // setTimeout( () => {
        // document.getElementById("myBar").style.height = scrolled + "%";
       
        // document.getElementById("discover").style.filter = "blur(5px)";
        // document.getElementById("discover").style.filter = "grayscale(100%)";
      // }, 150);
    },

  },
}
</script>

<style lang="scss" scoped>
.screen{
  height: 100vh;
}
.screen-mobile{
  height: 100vh;
}
// .memory-cards{
//   width: 50vw;
// }
.star{
  background-image: url(../assets/images/star2.png);
  background-size: cover;
}
.devide{
  background-image: url(../assets/images/background.png);
  background-size: cover;
}
.discover{
  background-image: url(../assets/images/discover.png);
  background-size: cover;
  background-position: center;
  // filter: blur(5px);
  // -webkit-filter: blur(5px);
}
</style>