<template>
<div class="toggle-bar">
  <!-- <div class="header">
    <img src="http://cometoorderva.com/wp-content/uploads/2015/10/toggl-logo.png">
    <p>Ticket-302 Verslag van Marleen lezen</p>
    <div class="progress-bar" id="hours"></div>
    <div class="progress-bar" id="minutes"></div>
    <div class="progress-bar" id="seconds"></div>
  </div> -->
  <div class="header">
    <div class="progress-container">
      <div class="progress-bar" id="myBar3"></div>
      <div class="image"><p class="arrow pt-5 pl-4">&darr;</p></div>
      <!-- <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="../assets/images/mario.png"
          transition="scale-transition"
          width="40"
        /> -->
    </div>  
  </div>
</div>
</template>

<script>
export default {
  name: 'ToggleBarTime',
  components: {
  },
  data: () => ({
    // scroll: false,
  }),
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
        // let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        // let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        // let scrolledHours = (winScroll / height) * 8;
        // let scrolledMin = (winScroll / height) * 480 - Math.floor(scrolledHours) * 60;
        // let scrolledSec = (winScroll / height) * 28800 - (Math.floor(scrolledMin) * 60 + Math.floor(scrolledHours) * 60 * 60);
        // // let hours = this.doubleDigits(Math.floor(scrolledHours));
        // let mins = this.doubleDigits(Math.floor(scrolledMin))
        // let secs = this.doubleDigits(Math.floor(scrolledSec))
        // document.getElementById("hours").innerHTML = Math.floor(scrolledHours) + ":" ;
        // document.getElementById("minutes").innerHTML = mins + ":" ;
        // document.getElementById("seconds").innerHTML = secs ;

        let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        let scrolled = (winScroll / height) * 100;

        setTimeout( () => {
          document.getElementById("myBar3").style.height = scrolled + "%";
        }, 150);
    },
    // doubleDigits(n){
    //   return n > 9 ? "" + n: "0" + n;
    // }
  }
}
</script>

<style scoped>
/* .toggle-bar {
  margin: 0;
  font-size: 28px;
  font-family: Arial, Helvetica, sans-serif;
} */
/* .header {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  box-shadow: 0px 2px 30px rgb(83, 83, 83);
  height: 40px;
  background: rgb(255, 255, 255);
  float: left;
} */
/* img{
  float: left;
  height: 40px;
  margin-left: 30px;
}
p {
  float: left;
  height: 40px;
  width: 60%;
  margin: 0 0 0 10%;
  padding-top: 5px;
} */
/* .progress-bar {
  height: 40px;
  float: left;
  padding-top: 5px;
} */
.toggle-bar {
  height: 70vw;
}
.header {
  /* position: fixed; */
  /* top: 100px; */
  z-index: 1;
  /* width: 3%; */
  /* background-color: #f1f1f1; */
  height: 100%;
}
.progress-container {
  width: 50px;
  height: 90%;
  /* background: #ccc; */
}
.progress-bar {
  height: 0%;
  /* background: #04AA6D; */
  width: 90%;
}
.image{
  /* background-image: url("../assets/images/mario2.png"); */
  /* background-position: center; */
  /* background-attachment: fixed; */
  /* background: cover; */
  background-size: cover;
  width: 100%;
  height: 10%;
  background: #9c9c9c;
  border-radius: 25px;
}
/* .animation{
  animation-name: example;
  animation-duration: 2s;
}

@keyframes example {
  0%   {background-image: url("../assets/images/mario.png")}
  25%  {background-image: url("../assets/images/mario2.png")}
  50%  {background-image: url("../assets/images/mario.png")}
  100% {background-image: url("../assets/images/mario2.png")}
} */

.arrow {
  animation: mymove 1s infinite;
  font-size: 30px;
}

@keyframes mymove {
  0% {margin-top: 10px;}
  50% {margin-top: 20px;}
  100% {margin-top: 10px;}
}
</style>