<template>
    <div class="container">
        <!-- <h1>JavaScript - Drag and Drop</h1> -->
        <div class="drop-targets">
            <div class="box">
                <div class="item" id="item" draggable="true">
                </div>
            </div>
            <div class="lego box">
                <!-- <v-img src="../assets/images/lego.png"></v-img> -->
            </div>
            <div class="box"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DragDiv',
    // props: {
    //   msg: Object,
    // },
    data: () => ({
        // scroll: false,
    }),
    mounted: function () {
        let item = document.querySelector('.item')
        let boxes = document.querySelectorAll('.box')

        item.addEventListener('dragstart', this.dragStart);
        boxes.forEach(box => {
            box.addEventListener('dragenter', this.dragEnter);
            box.addEventListener('dragover', this.dragOver);
            box.addEventListener('dragleave', this.dragLeave);
            box.addEventListener('drop', this.drop);
        });
    },
    destroyed () {
        // window.removeEventListener('scroll', this.handleScroll2);
    },
    methods: {
        dragStart(e) {
            e.dataTransfer.setData('text/plain', e.target.id);
            setTimeout(() => {
                e.target.classList.add('hide');
            }, 0);
        },
        /* drop targets */
        dragEnter(e) {
            e.preventDefault();
            e.target.classList.add('drag-over');
        },
        dragOver(e) {
            e.preventDefault();
            e.target.classList.add('drag-over');
        },
        dragLeave(e) {
            e.target.classList.remove('drag-over');
        },
        drop(e) {
            e.target.classList.remove('drag-over');

            // get the draggable element
            const id = e.dataTransfer.getData('text/plain');
            const draggable = document.getElementById(id);

            // add it to the drop target
            e.target.appendChild(draggable);

            // display the draggable element
            draggable.classList.remove('hide');
        }
    }
}
</script>

<style scoped>
/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */

/* body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    background-color: #fff;
    overflow: hidden;
} */
/* h1 {
    color: #323330;
} */
.container {
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.drop-targets {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0;

    height: 60vh;
    background-image: url("../assets/images/lego.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.box {
    height: 260px;
    width: 300px;
    /* border: solid 3px #ccc; */
    margin: 60px;
    /* align items in the box */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
/* .lego{
    height: 340px;
    width: 450px; */
    /* background-image: url("../assets/images/lego.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    /* background-color: #F0DB4F; */
/* } */

.drag-over {
    border: dashed 3px red;
}
.item {
    height: 310px;
    width: 330px;
    margin-right: 80px;
    margin-bottom: 40px;
    background-color: #f0da4f00;
    background-image: url("../assets/images/lego-piece2.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.hide {
    display: none;
}

</style>