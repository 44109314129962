<template>
  <div class="arrow">
    <v-img
    class="ml-5"
    alt="Vuetify Logo"
    :src="require('../assets/images/face.png')"
    width="300"
    />
  </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.arrow {
  animation: mymove 2s infinite;
}

@keyframes mymove {
  0% {margin-top: 0px;}
  50% {margin-top: 20px;}
  100% {margin-top: 0px;}
}
</style>