<template>
  <div :class="$isMobile() ? 'arrow-mobile' : 'arrow'">
    <v-img
    class="ml-5"
    alt="Vuetify Logo"
    :src="require('../assets/images/planet_light.png')"

    />
  </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.arrow {
  animation: mymove 2s infinite;
  z-index: 2;
  max-width: 600px;
  float: right;
}

@keyframes mymove {
  0% {margin-top: 50px;}
  50% {margin-top: 70px;}
  100% {margin-top: 50px;}
}

.arrow-mobile {
  animation: mymove-mobile 2s infinite;
  z-index: 2;
  max-width: 400px;
}

@keyframes mymove-mobile {
  0% {margin-top: 0px;}
  50% {margin-top: 10px;}
  100% {margin-top: 0px;}
}
</style>