<template>
  <v-container style="color: white" class="mb-15">
    <!-- <router-link style="color:white" to="/">Back to home</router-link> -->
    <div class="about my-15">
      <!-- <v-row>
        <v-col cols="12">
          <h1>Vision</h1>
        </v-col>
        <v-col cols="12">
          <p>
            Our vision is to create unique websites for unique projects and companies. 
            The goal is to create educational games and easy to use health care products. 
          </p>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col>
          <h1>What kind of website do you want?</h1>
          <p>You can find some examples below.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="mt-15">
          <v-card
            elevation="2"
            class="mx-5 white--text"
            shaped
            color="cyan lighten-2"
          >
            <v-img
              src="@/assets/images/chinese-new-year.jpg"
              height="200px"
              style="filter: grayscale(100%);"
            ></v-img>

            <v-card-title>
              <h2>Information</h2>
            </v-card-title>

            <v-card-subtitle>
              Example: Chinese acupuncture
            </v-card-subtitle>

            <v-card-actions>
              <v-btn
                color="Cyan darken-2"
                text
                href="https://bikangtang.nl/" target="_blank"
              >
                Go to website
              </v-btn>

              <v-spacer></v-spacer>

              <!-- <v-btn
                icon
                @click="show = !show"
              >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="mt-15">
          <v-card
            elevation="2"
            class="mx-5 white--text"
            shaped
            color="cyan lighten-2"
          >
            <v-img
              src="@/assets/images/memory.png"
              height="200px"
              style="filter: grayscale(100%);"
            ></v-img>

            <v-card-title>
               <h2>Serious gaming</h2>
            </v-card-title>

            <v-card-subtitle>
              Example: Maloha Chinese learning
            </v-card-subtitle>

            <v-card-actions>
              <v-btn
                color="Cyan darken-2"
                text
                href="https://maloha-chinese.nl" target="_blank"
              >
                Go to website
              </v-btn>

              <v-spacer></v-spacer>

              <!-- <v-btn
                icon
                @click="show = !show"
              >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="mt-15">
          <v-card
            elevation="2"
            class="mx-5 white--text"
            shaped
            color="cyan lighten-2"
          >
            <v-img
              src="@/assets/images/catch-game.png"
              height="200px"
              style="filter: grayscale(100%);"
            ></v-img>

            <v-card-title>
               <h2>Games</h2>
            </v-card-title>

            <v-card-subtitle>
              Example: Catch Game
            </v-card-subtitle>

            <v-card-actions>
              <v-btn
                color="Cyan darken-2"
                text
                href="https://catch-game-88c2f.web.app/" target="_blank"
              >
                Go to website
              </v-btn>

              <v-spacer></v-spacer>

              <!-- <v-btn
                icon
                @click="show = !show"
              >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="mt-15">
          <v-card
            elevation="2"
            class="mx-5 white--text"
            shaped
            color="cyan lighten-2"
          >
            <v-img
              src="@/assets/images/portfolio.png"
              height="200px"
              style="filter: grayscale(100%);"
            ></v-img>

            <v-card-title>
               <h2>Portfolio</h2>
            </v-card-title>

            <v-card-subtitle>
              Example: Marleen van Lubeek
            </v-card-subtitle>

             <v-card-actions>
              <v-btn
                color="Cyan darken-2"
                text
                href="https://marleenvanlubeek.nl/" target="_blank"
              >
                Go to website
              </v-btn>

              <v-spacer></v-spacer>

              <!-- <v-btn
                icon
                @click="show = !show"
              >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <p></p>
    </div>
  </v-container>
</template>

<script>
// import draggableDiv from '../components/dragableDiv'

export default {
  components: {
    // draggableDiv
  }
}
</script>