<template>
  <v-app>
      <div class="progress-container">
        <div class="progress-bar" id="myBar2"></div>
      </div>
    <!-- <nav class="header"> -->
      <v-app-bar
        class="bar"
        app
        color="black"
        dark
        inverted-scroll
        scroll-threshold="100"
        elevate-on-scroll
      >


        <!-- <v-container> -->
        <!-- <div class="d-flex align-center"> -->
          <!-- <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
            transition="scale-transition"
            width="40"

            shrink-on-scroll
            elevate-on-scroll
            prominent
            scroll-threshold="300"
          /> -->
          <v-app-bar-title class="ml-15"><h2>Life is a Game</h2></v-app-bar-title>
          <!-- <h2>Come play.</h2> -->
        <!-- </div> -->
        <v-spacer v-if="!$isMobile()"></v-spacer> 

          <h3 v-if="!$isMobile()" class="cyan--text text--lighten-3">Educational games and healthcare websites.</h3>


        <!-- <router-link v-if="this.$route.path != '/'" style="color:white" to="/">Home</router-link>
        <router-link v-else-if="this.$route.path != '/about'" style="color:white" to="/about">Cases</router-link>
        <router-link v-else-if="this.$route.path != '/vision'" style="color:white" to="/vision">Vision</router-link> -->
        <!-- </v-container> -->

      </v-app-bar>

    <!-- </nav> -->


    <v-main style="background-color: black;">
      <router-view/>
    </v-main>
    <v-footer fixed height="0px" color="black" width="100vw" style="border-radius: 25px 25px 0 0;">
      <v-row >
        <!-- color="none" class="ma-10" width="30vw"  style="border-radius: 25px; float: right; background-color: none;" -->
        <!-- <v-col class="ma-5" align="end" justify="end"  width="10vw">
          <v-btn style="color:green"
            class="mx-2"
            fab
            dark
            color="green">
            <v-icon dark large>
              mdi-play
            </v-icon>
          </v-btn>
          <router-link style="color:green" to="/about">
            <v-icon dark>
              mdi-play
            </v-icon>
          </router-link>
        </v-col> -->
        <v-col cols="8" lg="11" style="margin-top: -70px;" width="50vw">
          <draggableDiv width="50vw" v-if="!$isMobile()">
            <!-- <template slot="header">
              [[[ SOME CONTENT HERE]]]
            </template>
            <template slot="main" >
              [[[ SOME CONTENT HERE]]]
            </template>
            <template slot="footer">
              [[[ SOME CONTENT HERE]]]
            </template> -->
          </draggableDiv>

        </v-col>
        <!-- <v-spacer></v-spacer>  -->
        <v-col :style="$isMobile() ? 'margin-top: -80px;' : 'margin-top: -100px;'" cols="4" lg="1">
          <!-- <router-link v-if="this.$route.path == '/about'" style="color:white" to="/">Home</router-link>
          <router-link v-else-if="this.$route.path == '/'" style="color:white" to="/about">Want a website?</router-link> -->

          <!-- <h4 class="cyan--text text--lighten-2">Educational games and healthcare products.</h4> -->
        

            <v-btn 
              v-if="this.$route.path == '/about'"
              to="/"
              style="color:#cdf6fa"
              dark
              x-large
              fab
              color="#14a5b3">
              <!-- <p>Home</p> -->
              <v-icon dark x-large>
                mdi-home
              </v-icon>
            </v-btn>
            <v-btn 
              v-else-if="this.$route.path == '/'"
              to="/about"
              style="color:#cdf6fa"
              dark
              x-large
              rounded
              color="#14a5b3">
              Info
              <!-- <v-icon dark large>
                mdi-desktop-mac
              </v-icon> -->
            </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import draggableDiv from '@/components/dragableDiv'

export default {
  name: 'App',
  components: {
    draggableDiv
  },
  data: () => ({
    //
  }),
  mounted: function () {
    window.addEventListener('scroll', this.handleScroll2);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll2);
  },
  methods:{
    handleScroll2() {
      var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      var scrolled = (winScroll / height) * 100;
      document.getElementById("myBar2").style.width = scrolled + "%";
    },
  }
};
</script>

<style scoped>
/* .header {
  position: fixed;
  z-index: 1;
  width: 100%;
  bottom: 0;
  background-color: #f1f1f1;
} */
/* .bar{
  z-index: 0;
} */

/* The progress container (grey background) */
.progress-container {
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 8px;
  background: rgb(0, 0, 0);
}

/* The progress bar (scroll indicator) */
.progress-bar {
  height: 100%;
  background: #076070;
  width: 0%;
}
</style>