<template>
  <div ref="draggableContainer" :id="$isMobile() ? 'draggable-container-mobile' : 'draggable-container'" @mousedown="dragMouseDown">
    <div id="draggable-header" >
      <!-- <slot name="header"></slot> -->
      <v-row>
        <v-col cols="12" lg="10">
          <h2 v-if="!$isMobile()">Contact</h2>
          <h2 v-else>Life is a Game</h2>
          <!-- <h4>Educational games and healthcare products.</h4> -->
        </v-col>
        <v-col cols="2" justify="right" v-if="!$isMobile()">
          <v-img
            alt="Vuetify Logo"
            :src="require('@/assets/images/drag-icon-bl.png')"
            width="30px"
            style="float: right"
            />
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col>
        <p>Phone number:</p>
        <!-- <p>Address:</p> -->
        <p>E-mail:</p>
      </v-col>
      <v-col>
        <p>+31 613075878</p>
        <!-- <p>Street 993</p> -->
        <p>jmvlubeek@outlook.com</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3 v-if="$isMobile()" class="cyan--text text--lighten-4">Educational games and healthcare websites.</h3>
      </v-col>
    </v-row>
    <!-- <slot name="main"></slot>
    <slot name="footer"></slot> -->
  </div>
</template>

<script>
export default {
  name: 'draggableDiv',
  data: function () {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    }
  },
  methods: {
    dragMouseDown: function (event) {
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
      this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },
    closeDragElement () {
      document.onmouseup = null
      document.onmousemove = null
    }
  }
}
</script>

<style>
#draggable-container {
  position: absolute;
  z-index: 9;
  width: 25vw;
  -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  border-radius: 25px;
  background-color:rgb(35, 153, 168);
  padding: 20px;
}
#draggable-container-mobile {
  position: relative;
  z-index: 0;
  width: 90vw;
  -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  border-radius: 25px;
  background-color:rgb(35, 153, 168);
  padding: 20px;
}
#draggable-header {
  z-index: 10;
}
</style>