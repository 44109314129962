<template>
<div>
  <v-row class="header ma-15">
    <v-col>
      <div class="progress-container">
        <div class="progress-bar" id="myBar5"></div>
      </div>
    </v-col>
    <v-col>
      <div class="progress-container">
        <div class="progress-bar" id="myBar6"></div>
      </div>
    </v-col>
    <v-col>
      <div class="progress-container">
        <div class="progress-bar" id="myBar7"></div>
      </div>
    </v-col>
  </v-row>
</div>
</template>

<script>
export default {
  name: 'GraphBars',

  data: () => ({
    //
  }),
  mounted: function () {
    window.addEventListener('scroll', this.handleScroll2);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll2);
  },
  computed:{

  },
  methods:{
    handleScroll2() {
      var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      var scrolled = ((winScroll / height) * 100);
      var b1 = scrolled * 8
      var b2 = scrolled * 4.7
      var b3 = scrolled * 6.2

      var b1scroll = this.switch(b1)
      var b2scroll = this.switch(b2)
      var b3scroll = this.switch(b3)

      document.getElementById("myBar5").style.height = b1scroll + "%";
      document.getElementById("myBar6").style.height = b2scroll + "%";
      document.getElementById("myBar7").style.height = b3scroll + "%";
    },
    switch(b1){
      var b1scroll = 0


      // if (b1 < 50){
      //   b1scroll = b1 + 50
      // } else if (b1 < 100) {
      //   b1scroll = 100 - b1
      // } else if (b1 <150){
      //   b1scroll = b1 - 200
      // } else if (b1 < 200){
      //   b1scroll = 100 - (b1 - 300)
      // } else if (b1 < 250){
      //   b1scroll = b1 - 400
      // } else if (b1 < 300){
      //   b1scroll = 100 - (b1 - 500)
      // } else if (b1 < 350){
      //   b1scroll = b1 - 600
      // } else if (b1 < 400){
      //   b1scroll = 100 - (b1 - 700)
      // }


      var number = parseInt(b1 / 100)
      if (number === 0){
        b1scroll = b1
        // if divided by 2, zero comes out.
      } else if ((number % 2) === 0) {
        b1scroll = b1 - (100 * number)
      } else {
        b1scroll = 100 - (b1 - (100 * number))
      }

      // if (b1 < 100){
      //   b1scroll = b1
      // } else if (b1 < 200) {
      //   b1scroll = 100 - (b1 - 100)
      // } else if (b1 <300){
      //   b1scroll = b1 - 200
      // } else if (b1 < 400){
      //   b1scroll = 100 - (b1 - 300)
      // } else if (b1 < 500){
      //   b1scroll = b1 - 400
      // } else if (b1 < 600){
      //   b1scroll = 100 - (b1 - 500)
      // } else if (b1 < 700){
      //   b1scroll = b1 - 600
      // } else if (b1 < 800){
      //   b1scroll = 100 - (b1 - 700)
      // }
      return(b1scroll)
    }
  }
}
</script>

<style scoped>
/* Style the header: fixed position (always stay at the top) */
.header {
  /* position: fixed; */
  /* top: 0; */
  z-index: 1;
  height: 300px;
  max-width: 400px;
  background-color: #000000;
  border-bottom: 5px solid rgb(255, 255, 255)!important;
  border-left: 5px solid rgb(255, 255, 255)!important;
}

/* The progress container (grey background) */
.progress-container {
  width: 20px;
  height: 100%;
  background: rgb(10, 104, 133);
}

/* The progress bar (scroll indicator) */
.progress-bar {
  height: 0%;
  background: #000000;
  width: 20px;
  padding-bottom: 0;
}
</style>