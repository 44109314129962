import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    totalScore: 0,
    openGame: {
      topography: false, 
      searchgame: false, 
      characters: false,
      popup: false,
      hsk: false},

    button: null,
    checked: false,
    gameOver: false,

    showCorrect: false,
    showClicked: {},
    clicked: false,
    
    hsk: 1,
    level: 0,

    stopPlaying: 0,

    // select: {name: 'Numbers', number: 0},
  },
  mutations: {
    // LEVEL1 (state) {
    //   state.level = "1"
    //   // console.log(state.level)
    // },
    // LEVEL2 (state) {
    //   state.level = "2"
    //   // console.log(state.level)
    // },
    // LEVEL3 (state) {
    //   state.level = "3"
    //   // console.log(state.level)
    // },
    ADDSCORE (state, score) {
      state.totalScore = state.totalScore + score
      // console.log(state.level)
    },
    DEDUCTSCORE (state, score) {
      state.totalScore = state.totalScore - score
      // console.log(state.level)
    },
    OPENGAME (state, open) {
      if (open == 'topography'){
        state.openGame.topography = true
      } else if (open == 'searchgame'){
        state.openGame.searchgame = true
      } else if (open == 'characters'){
        state.openGame.characters = true
      } else if (open == 'popup'){
        if(state.openGame.popup == false){
          console.log('hi')
          state.openGame.popup = true
          state.openGame.hsk = true
        } else {
          state.openGame.popup = false
        }
      }
    },
    LEVEL (state, number) {
      state.level = number
      // console.log(state.level)
    },
    HSK (state, number) {
      state.hsk = number
      // state.select.number = 1
      state.level = 0
    },
    BUTTONSTATE (state, answer) {
      state.button = answer
      // console.log(state.button)
    },
    BUTTONCHECK (state, check) {
      state.checked = check
      // console.log(state.button)
    },
    GAMEOVER (state, done) {
      state.gameOver = done
    },
    SHOWCORRECT (state, { correct, id, clicked }){
      state.showClicked = id
      state.showCorrect = correct
      state.clicked = clicked
    },
    STOPPLAYING(state){
      console.log(state.stopPlaying)
      state.stopPlaying = state.stopPlaying + 1
    }
  },
  actions: {
  },
  modules: {
  }
})
