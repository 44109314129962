<template>
<div class="toggle-bar">
  <div class="header">
    <div class="progress-container">
      <div class="progress-bar" id="myBar"></div>
      <!-- <div class="image"><p class="arrow pt-5 pl-4">&darr;</p></div> -->
      <!-- <p style="font-size: 50px; color: white"> starrrrrrrr</p> -->
        <v-img
          class="ml-5"
          id="image"
          alt="yellow stars"
          :src="require('../assets/images/stars_yellow2.png')"
          style="background-size: cover; height: 100%;"
        />
    </div>  
  </div>
</div>
</template>

<script>
export default {
  name: 'ToggleBarTime',
  components: {
  },
  data: () => ({
    // scroll: false,
  }),
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {

        let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        // let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        // window.innerHeight
        let scrolled = ((winScroll / height) * 50);
        let transparant = 1 - (winScroll / (height/1.7));
        document.getElementById("image").style.opacity = transparant;

        setTimeout( () => {
          // document.getElementById("myBar").style.height = scrolled + "%";
          document.getElementById("myBar").style.height = scrolled + "%";
        }, 150);
    },
    // doubleDigits(n){
    //   return n > 9 ? "" + n: "0" + n;
    // }
  }
}
</script>

<style scoped>
.toggle-bar {
  height: 100%;
  /* background-color: blue; */
}
.header {
  /* position: fixed; */
  /* top: 100px; */
  /* z-index: 1; */
  /* width: 3%; */
  /* background-color: #f1f1f1; */
  height: 100%;
  background-image: url(../assets/images/stars_white2.png);
  background-size: cover;
    -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg);
}
.progress-container {
  width: 100vw;
  height: 90%;
  /* background: #ccc; */
  background-image: url(../assets/images/stars_blue2.png);
  background-size: cover;
    -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg);
}
.progress-bar {
  height: 0%;
  /* background: #04AA6D; */
  width: 90%;
}
.image{
  /* background-image: url("../assets/images/mario2.png"); */
  /* background-position: center; */
  /* background-attachment: fixed; */
  /* background: cover; */
  background-size: cover;
  width: 100%;
  height: 10%;
  background: #9c9c9c;
  border-radius: 25px;
}

/* .arrow {
  animation: mymove 1s infinite;
  font-size: 30px;
}

@keyframes mymove {
  0% {margin-top: 10px;}
  50% {margin-top: 20px;}
  100% {margin-top: 10px;}
} */
</style>