<template>
  <div class="memory-cards">
    <v-row class="py-5 px-5 px-lg-15">
      <v-col cols="12" lg="10">
        <v-row class="text-center mt-1 px-1" justify="center">
            <v-col
            v-for="(card, i) in cardSet"
            :key="i"
            :href="card.href"
            class="pa-0"
            cols="6" lg="3"
            @click="turnCard(card)"
            >
            <MemoryCard :msg="card"/>
            </v-col>
        </v-row>
        </v-col>
      <v-col cols="2" lg="2" v-if="!$isMobile()">
        <h2 class="pt-10 pl-10" style="color: white; transform: rotate(20deg);">Find the matching cards!</h2>
        <h1 class="pl-10" style="color: white; font-size: 100px;">&crarr;</h1>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MemoryCard from '../components/MemoryCard';
// import store from '../store'

export default {
  name: 'Home',

  components: {
    MemoryCard,
  },
  created: function () {
    // let array = this.cards.filter(element => element.level == store.state.level && element.hsk == store.state.hsk)
    let array = []
    console.log(this.$isMobile())

    if (this.$isMobile()){
      // for (let i = 0; i < 6; i++) {
      //   array.push();
      // }

      this.cards.forEach(element => 
        {if (element.id < 6){
          array.push(element);
          console.log(element.id)
        }}
      );
      
    } else {
      console.log("hello")
      array = this.cards
    }

    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    this.cardSet = array;
  },
  // destroyed () {
  //   window.removeEventListener('scroll', this.handleScroll);
  // },
    methods: {
    // startAgain(){
    //   let array = this.cards.filter(element => element.level == store.state.level && element.hsk == store.state.hsk)
    //   // console.log(array)

    //   for (let i = 0; i < array.length; i++) {
    //     array[i].show = false
    //     array[i].select = false
    //     array[i].guessed = false
    //     // console.log(array[i])
    //   }
    //   this.cardsTurned = 0
    //   this.setFound = null
    //   this.cardSet = null
    //   // this.points = 0

    //   let currentIndex = array.length, temporaryValue, randomIndex;
    //   while (0 !== currentIndex) {
    //     randomIndex = Math.floor(Math.random() * currentIndex);
    //     currentIndex -= 1;
    //     temporaryValue = array[currentIndex];
    //     array[currentIndex] = array[randomIndex];
    //     array[randomIndex] = temporaryValue;
    //   }
    //   this.cardSet = array;
    // },
    turnCard(card){
      if (card.select == true){
        if (this.cardsTurned == 0){
          this.cardsTurned = 1
        } else {
          this.cardsTurned = 2
          const result = this.cardSet.filter(element => element.select == true);
            if (result[0].set == result[1].set){
                this.setFound = true
                // this.points = this.points + 10;

                let cardOne = this.cardSet.filter(element => element.select == true)[0]
                cardOne.select = false

                let cardTwo = this.cardSet.filter(element => element.select == true)[0]
                cardTwo.select = false
                
                this.cardsTurned = 0
                this.setFound = null

                setTimeout( () => {
                  cardOne.guessed = true
                  cardTwo.guessed = true

                  cardOne.show = false
                  cardTwo.show = false

                  // if (this.cardSet.filter(element => element.guessed == false).length == 0){
                  //   this.gameOver()
                  // }
                }, 1000);

              } else {
                this.setFound = false
                // this.points = this.points - 5;
                let cardOne = this.cardSet.filter(element => element.select == true)[0]
                cardOne.select = false
                let cardTwo = this.cardSet.filter(element => element.select == true)[0]
                cardTwo.select = false

                this.cardsTurned = 0
                this.setFound = null

                setTimeout( () => {
                  cardOne.show = false
                  cardTwo.show = false
                }, 1000);
              }
        }
      } else {
          if (this.cardsTurned == 2){
            this.cardsTurned = 1
            this.setFound = null
          } else {
            this.cardsTurned = 0
          }
      }
    },
  },
  data: () => ({
    cardsTurned: 0,
    setFound: null,
    cardSet: null,
    points: 20,
    message: "Match the sound to the right picture.",
    image: "assets/images/memory3-expl.png",
    cards: [
            {
        level: 0,
        hsk: 1,
        id: 0,
        set: '1',
        img: "assets/images/sound.png",
        sound: "assets/sentences/1-yi.mp3",
        show: true,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: 1,
        set: '1',
        img: "assets/images/number1.png",
        // img: 'https://www.pinclipart.com/picdir/middle/16-161946_animal-clip-art-number-one-vector-site-number.png',
        sound: "assets/sentences/1-yi.mp3",
        show: true,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: 2,
        set: '2',
        img: "assets/images/sound.png",
        sound: "assets/sentences/2-er.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: 3,
        set: '2',
        img: "assets/images/number2.png",
        // img: 'https://i.pinimg.com/originals/7c/23/2b/7c232bbc2fb9ec5f35948233bc037f50.png',
        sound: "assets/sentences/2-er.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: 4,
        set: '3',
        img: "assets/images/sound.png",
        sound: "assets/sentences/3-san.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: 5,
        set: '3',
        img: "assets/images/number3.png",
        // img: 'https://media.istockphoto.com/photos/neon-3d-font-blue-and-pink-neon-light-3d-rendering-number-3-picture-id1250020951?k=6&m=1250020951&s=170667a&w=0&h=I_UW6D-gOxDv_wJnpg0rwRpcOpaX7UPG1ml5DLP-Qmk=',
        sound: "assets/sentences/3-san.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: 6,
        set: '4',
        img: "assets/images/sound.png",
        sound: "assets/sentences/4-si.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: 7,
        set: '4',
        img: "assets/images/number4.png",
        sound: "assets/sentences/4-si.mp3",
        show: false,
        select: false,
        guessed: false,
      },
    ],
  }),
}
</script>

<style scoped>

</style>